// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  .header {
    background          : url('../assets/header.png') no-repeat;
    height              : 200px;
    background-size     : cover;
    background-position : center bottom;
    width               : 100%;

  }
  .header-big {
    //background          : url('../assets/header-big.png') no-repeat;
    //height              : 250px;
    //background-size     : cover;
    //background-position : center bottom;
    //width               : 100%;
    //position : absolute;
  }
  .keret {
    max-width   : 800px;
    margin      : 0 auto;
    padding-top : 0;
    &:before {
      width           : 100%;
      height          : 50%;
      content         : "";
      top             : 0;
      right           : 0;
      left            : 0;
      position        : absolute;
      z-index         : 0;
      background      : url('../assets/tri.svg') 100% 0 no-repeat;
      background-size : 100% 100%;
      @media (max-width : 900px) {
        background : none;
      }
    }
    .text-blue {
      background: white;
      padding: 20px 0;
      border-radius: 5px;
    }
    .text-blue {
      @media (max-width : 900px) {
        color : white;
        background: transparent;
      }
      @media (max-width : 600px) {

        border-radius: 0;
        font-size: 20px;
      }
    }
  }
  ion-content {
    //--ion-background-color : var(--ion-color-primary);
    --background : url('../assets/bg-web.png') 0/cover no-repeat;

  }

  --ion-font-family                : 'Work Sans', sans-serif;
  h1 {
    font-size : 27px;
  }
  h2 {
    font-size : 20px;
  }

  .text-blue {
    color : var(--ion-color-secondary);
  }

  ion-button {
    --padding-top    : 25px;
    --padding-start  : 10px;
    --padding-end    : 10px;
    --padding-bottom : 25px;
    font-size        : 14px;
    font-weight      : bold;
    letter-spacing   : 2px;
    display          : block;
    height           : 60px;
    width            : 160px;
    --border-radius  : 3px;
    --box-shadow     : none;
    --box-shadow     : 10px 10px 43px rgba(0, 0, 0, 0.5);
  }

  /** primary **/
  --ion-color-primary              : #4AA8F2;
  --ion-color-primary-shade        : #4AA8F2;
  --ion-color-primary-tint         : #4AA8F2;

  /** secondary **/
  --ion-color-secondary            : #0D4679;
  --ion-color-secondary-shade      : #10599b;
  --ion-color-secondary-tint       : #0D4679;

  .text-secondary {color : var(--ion-color-secondary) !important;}

  /** tertiary **/
  --ion-color-tertiary             : #2F96EC;
  --ion-color-tertiary-shade       : #2F96EC;
  --ion-color-tertiary-tint        : #2F96EC;

  /** success **/
  --ion-color-success              : #2dd36f;
  --ion-color-success-rgb          : 45, 211, 111;
  --ion-color-success-contrast     : #ffffff;
  --ion-color-success-contrast-rgb : 255, 255, 255;
  --ion-color-success-shade        : #28ba62;
  --ion-color-success-tint         : #42d77d;

  /** warning **/
  --ion-color-warning              : #ffc409;
  --ion-color-warning-rgb          : 255, 196, 9;
  --ion-color-warning-contrast     : #000000;
  --ion-color-warning-contrast-rgb : 0, 0, 0;
  --ion-color-warning-shade        : #e0ac08;
  --ion-color-warning-tint         : #ffca22;

  /** danger **/
  --ion-color-danger               : #eb445a;
  --ion-color-danger-rgb           : 235, 68, 90;
  --ion-color-danger-contrast      : #ffffff;
  --ion-color-danger-contrast-rgb  : 255, 255, 255;
  --ion-color-danger-shade         : #cf3c4f;
  --ion-color-danger-tint          : #ed576b;

  /** dark **/
  --ion-color-dark                 : #222428;
  --ion-color-dark-rgb             : 34, 36, 40;
  --ion-color-dark-contrast        : #ffffff;
  --ion-color-dark-contrast-rgb    : 255, 255, 255;
  --ion-color-dark-shade           : #1e2023;
  --ion-color-dark-tint            : #383a3e;

  /** medium **/
  --ion-color-medium               : #92949c;
  --ion-color-medium-rgb           : 146, 148, 156;
  --ion-color-medium-contrast      : #ffffff;
  --ion-color-medium-contrast-rgb  : 255, 255, 255;
  --ion-color-medium-shade         : #808289;
  --ion-color-medium-tint          : #9d9fa6;

  /** light **/
  --ion-color-light                : #f4f5f8;
  --ion-color-light-rgb            : 244, 245, 248;
  --ion-color-light-contrast       : #000000;
  --ion-color-light-contrast-rgb   : 0, 0, 0;
  --ion-color-light-shade          : #d7d8da;
  --ion-color-light-tint           : #f5f6f9;
}
